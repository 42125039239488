import React from 'react';
import { Link } from 'react-router-dom';
import ContentHelper from "../services/ContentHelper";

import Session from '../services/Session';
import $ from 'jquery';

export default class MobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            is_logged_in: false,
            logo: <></>
        };

        Session.CheckIsLoggedIn().then(is_logged_in => {
			this.setState({ is_logged_in });
		});
    }

	componentDidMount() {
        ContentHelper.GetLogoImg("logo", "55px").then(logo => this.setState({ logo }));

        Promise.all([
			ContentHelper.GetString("custom_static_page_url"),
			ContentHelper.GetString("custom_static_page_caption")
		]).then(([url, caption]) => {
            let customStaticURL = "/para-empresas";
            let customStaticCaption = "Para empresas";

			if (url && url.length) customStaticURL = url;
			if (caption && caption.length) customStaticCaption = caption;

            this.setState({
                customStaticURL,
                customStaticCaption
            });
		});

		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		if(window.scrollY > 1) {
			document.body.classList.add('scrolled');
		} else {
			document.body.classList.remove('scrolled');
		}
	}

	render() {
		return (
            <React.Fragment>
			    <nav className="mobile-navbar">
                    <button
                        className="menu-button"
                        onClick={() => {
                            $("body").addClass("scroll-lock");
                            $('.mobile-menu-overlay, .mobile-menu').addClass('active');
                        }}
                    ><img alt="" src="/static/images/menu-icon.png" /></button>
                    <Link to="/">{this.state.logo}</Link>
                    <Link to="/restaurantes" className="reservar-ahora">Reservar ahora</Link>
                </nav>
                <div className="mobile-menu-overlay" onClick={this.closeMenu}/>
                <div className="mobile-menu">
                    <div className="user-menu">
                        {Session.RenderUserActions(this.closeMenu.bind(this))}
                    </div>

                    <Link to="/contacto" onClick={this.closeMenu}>Contacto</Link>
                    <Link to="/preguntas-frecuentes" onClick={this.closeMenu}>Preguntas frecuentes</Link>
                </div>
            </React.Fragment>
		);
	}

    closeMenu() {
        $("body").removeClass("scroll-lock");
        $('.mobile-menu-overlay, .mobile-menu').removeClass('active');
    }
}
